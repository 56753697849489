import React from "react";
import { withRouter } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Container, Row, Col } from "reactstrap";

import { useAuth0 } from "../react-auth0-spa";

import Questions from "../components/Questions";

const GET_SURVEYS = gql`
  query($candidate_email: String) {
    survey {
      id
      name
      questions(order_by: { id: asc }) {
        id
        text
        options {
          id
          text
        }
        responses(
          order_by: { id: asc }
          where: { candidate_email: { _eq: $candidate_email } }
        ) {
          survey_question_id
          survey_question_option_id
          comment
        }
      }
    }
  }
`;

const Surveys = props => {
  const { user } = useAuth0();

  const { data, error, loading } = useQuery(GET_SURVEYS, {
    variables: { candidate_email: user.email },
    fetchPolicy: "network-only"
  });

  if (loading) {
    return <div></div>;
  }
  if (error) {
    return <div>Error! {error.message}</div>;
  }

  if (data.survey.length === 0) {
    return (
      <div>
        <h1 style={{ textAlign: "center", marginBottom: "20px" }}>
          No surveys
        </h1>
      </div>
    );
  }

  const survey = data.survey[0];

  return (
    <Container>
      <Row>
        <Col>
          <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
            {survey.name}
          </h3>
        </Col>
      </Row>

      <Row>
        <Col sm="12" md={{ size: 8, offset: 2 }}>
          <Questions survey={survey} />
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Surveys);
