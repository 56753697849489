import React from "react";
import { withRouter } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Button } from "reactstrap";

const GET_MY_CODE = gql`
  {
    my_code {
      code
    }
  }
`;

const MyCode = props => {
  const { data, error, loading } = useQuery(GET_MY_CODE, {
    fetchPolicy: "network-only"
  });

  if (loading) {
    return <div></div>;
  }
  if (error) {
    return <div>Error! {error.message}</div>;
  }

  const goToSurveys = () => {
    props.history.push("/surveys");
  };

  const myCode = data.my_code.code;

  return (
    <div>
      {myCode && (
        <h1 style={{ textAlign: "center", marginBottom: "20px" }}>
          Code: {myCode}
        </h1>
      )}
      {!myCode && (
        <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
          Fill in the{" "}
          <Button color="primary" size="lg" onClick={goToSurveys}>
            survey
          </Button>{" "}
          to get the code
        </h3>
      )}
    </div>
  );
};

export default withRouter(MyCode);
