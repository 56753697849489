import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider, TOKEN_PARAM } from "./react-auth0-spa";
import config from "./auth_config.json";
import history from "./utils/history";

import { ApolloProvider } from "@apollo/react-hooks";
import ApolloClient from "apollo-boost";

const GRAPHQL_SERVER_ADDRESS = "https://acaisoft.pl/v1/graphql";
// const GRAPHQL_SERVER_ADDRESS = "http://localhost:5000/v1/graphql";
// const GRAPHQL_SERVER_ADDRESS = "https://192.168.0.57:5001/v1/graphql";

const client = new ApolloClient({
  uri: GRAPHQL_SERVER_ADDRESS,
  request: async operation => {
    const token = localStorage.getItem(TOKEN_PARAM);
    operation.setContext({
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }
});

const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

ReactDOM.render(
  <ApolloProvider client={client}>
    <Auth0Provider
      domain={config.domain}
      client_id={config.clientId}
      redirect_uri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience="http://34.77.209.148/v1/graphql"
      scope="openid"
    >
      <App />
    </Auth0Provider>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
