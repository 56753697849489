import React, { Fragment, useState } from "react";
import { Container, Row, Col, Button, Input } from "reactstrap";
import { useAuth0 } from "../react-auth0-spa";
import logo from "../assets/acaisoft-avatar-new.jpg";

const Home = () => {
  const { user, isAuthenticated, loginWithRedirect } = useAuth0();
  const [consentGiven, setConsent] = useState(false);

  return (
    <Fragment>
      <div className="text-center">
        <img
          className="mb-2 app-logo"
          src={logo}
          alt="Acaisoft logo"
          width="120"
        />
        {!isAuthenticated && (
          <h4>Please log in to take part in the survey and get your code.</h4>
        )}
        <hr />
        {isAuthenticated && <h1>Hello, {user.name}</h1>}
        {!isAuthenticated && (
          <Container>
            <Row style={{ marginBottom: "20px" }}>
              <Col sm="12" md={{ size: 2, offset: 5 }}>
                <Button
                  block
                  id="qsLoginBtn"
                  color="primary"
                  className="btn-margin"
                  disabled={!consentGiven}
                  onClick={() =>
                    loginWithRedirect({ appState: { consentGiven } })
                  }
                >
                  Log in
                </Button>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md={{ size: 6, offset: 3 }}>
                <label>
                  <Input
                    type="checkbox"
                    defaultChecked={consentGiven}
                    onChange={e => setConsent(e.target.checked)}
                  />
                  I consent to the processing by Acaisoft Polska Sp. z o.o. my
                  personal data for the purposes of participation in future
                  recruitments conducted by Acaisoft Poland sp.z o.o.
                </label>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </Fragment>
  );
};

export default Home;
