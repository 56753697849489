import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { useAuth0 } from "../react-auth0-spa";

import SwipeableViews from "react-swipeable-views";
import { Row, Col, Button, ListGroup, ListGroupItem, Input } from "reactstrap";

const SEND_RESPONSES = gql`
  mutation(
    $responses: [survey_question_response_insert_input!]!
    $candidate_email: String
    $survey_id: Int
  ) {
    delete_survey_question_response(
      where: {
        candidate_email: { _eq: $candidate_email }
        question: { survey_id: { _eq: $survey_id } }
      }
    ) {
      affected_rows
    }
    insert_survey_question_response(objects: $responses) {
      affected_rows
    }
  }
`;

const Questions = props => {
  const { user } = useAuth0();
  const [index, setIndex] = useState(0);

  const [sendResponsesMutation] = useMutation(SEND_RESPONSES, {
    onCompleted() {
      props.history.push("/my-code");
    }
  });

  let r = [];
  props.survey.questions.forEach(q => {
    r = r.concat(
      q.responses.map(r => ({
        survey_question_id: r.survey_question_id,
        survey_question_option_id: r.survey_question_option_id,
        comment: r.comment
      }))
    );
  });

  const [responses, setResponses] = useState(r);

  const QUESTIONS_CNT = props.survey.questions.length;

  const handleSwitch = index => {
    setIndex(index);
  };

  const handleChangeIndex = index => {
    setIndex(index);
  };

  const handleOptionClick = ({ checked, question_id, option_id }) => {
    let updatedResponses = responses.map(r => r);
    const filtered = updatedResponses.filter(
      r =>
        r.survey_question_id === question_id &&
        r.survey_question_option_id === option_id
    );
    if (checked && filtered.length === 0) {
      updatedResponses.push({
        survey_question_id: question_id,
        survey_question_option_id: option_id
      });
    }
    if (!checked && filtered.length > 0) {
      updatedResponses.splice(updatedResponses.indexOf(filtered[0]), 1);
    }
    setResponses(updatedResponses);
  };

  const isActive = (question_id, option_id) => {
    const item = responses.filter(
      r =>
        r.survey_question_id === question_id &&
        r.survey_question_option_id === option_id
    );
    return item.length > 0;
  };

  const findResponse = question_id => {
    const item = responses.filter(
      r =>
        r.survey_question_id === question_id &&
        r.survey_question_option_id === null
    );
    return item.length > 0 ? item[0].comment : "";
  };

  const handleOpenOptionChange = ({ text, question_id }) => {
    let updatedResponses = responses.map(r => r);
    const filtered = updatedResponses.filter(
      r =>
        r.survey_question_id === question_id &&
        r.survey_question_option_id === null
    );

    if (filtered.length > 0) {
      filtered[0].comment = text;
    } else {
      updatedResponses.push({
        survey_question_id: question_id,
        survey_question_option_id: null,
        comment: text
      });
    }

    setResponses(updatedResponses);
  };

  const sendResponses = () => {
    const candidate_email = user.email;

    sendResponsesMutation({
      variables: {
        candidate_email,
        survey_id: props.survey.id,
        responses: responses.map(r => ({
          ...r,
          candidate_email
        }))
      }
    });
  };

  return (
    <div className="questions">
      <SwipeableViews
        index={index}
        onChangeIndex={handleChangeIndex}
        enableMouseEvents={true}
      >
        {props.survey.questions.map(question => (
          <div key={question.id} className="question">
            <h4>
              {question.text} ({index + 1}/{QUESTIONS_CNT})
            </h4>

            {question.options.length === 0 && (
              <Input
                className="open-question"
                type="textarea"
                name="text"
                placeholder="Please write your answer here"
                defaultValue={findResponse(question.id)}
                onChange={e =>
                  handleOpenOptionChange({
                    text: e.target.value,
                    question_id: question.id
                  })
                }
              />
            )}
            {question.options.length > 0 && (
              <ListGroup>
                {question.options.map(option => (
                  <label
                    htmlFor={`option_${question.id}_${option.id}`}
                    key={option.id}
                  >
                    <ListGroupItem
                      action
                      active={isActive(question.id, option.id)}
                    >
                      <input
                        id={`option_${question.id}_${option.id}`}
                        type="checkbox"
                        style={{ display: "none" }}
                        defaultChecked={isActive(question.id, option.id)}
                        onChange={e =>
                          handleOptionClick({
                            checked: e.target.checked,
                            question_id: question.id,
                            option_id: option.id
                          })
                        }
                      />
                      {option.text}
                    </ListGroupItem>
                  </label>
                ))}
              </ListGroup>
            )}
          </div>
        ))}
        <div className="open-question">
          <h3 style={{ textAlign: "center" }}>Thank you for your time</h3>
          <Col sm="12" md={{ size: 6, offset: 3 }}>
            <Button color="primary" size="lg" block onClick={sendResponses}>
              Submit your results
            </Button>
          </Col>
        </div>
      </SwipeableViews>

      <Row>
        <Col xs="6" md={{ size: 4, offset: 0 }}>
          {index > 0 && (
            <Button
              outline
              color="primary"
              block
              onClick={() => handleSwitch(index - 1)}
            >
              &lt;&lt; Prev
            </Button>
          )}
        </Col>
        <Col xs="6" md={{ size: 4, offset: 4 }}>
          {index < QUESTIONS_CNT && (
            <Button
              outline
              color="primary"
              block
              onClick={() => handleSwitch(index + 1)}
            >
              Next &gt;&gt;
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(Questions);
