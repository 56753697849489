import React, { useState, useEffect, useContext } from "react";

import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

import createAuth0Client from "@auth0/auth0-spa-js";

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

const CREATE_SESSION = gql`
  mutation {
    create_session
  }
`;

// export
export const TOKEN_PARAM = "api_token";
export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  // const [popupOpen, setPopupOpen] = useState(false);

  const [createSession] = useMutation(CREATE_SESSION);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes("code=")) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
        const token = await auth0FromHook.getTokenSilently();
        localStorage.setItem(TOKEN_PARAM, token);
        createSession();
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  // const loginWithPopup = async (params = {}) => {
  //   debugger
  //   setPopupOpen(true);
  //   try {
  //     await auth0Client.loginWithPopup(params);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setPopupOpen(false);
  //   }
  //   const user = await auth0Client.getUser();
  //   setUser(user);
  //   setIsAuthenticated(true);
  // };

  const handleRedirectCallback = async () => {
    debugger;
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  const logout = async (params = {}) => {
    localStorage.removeItem(TOKEN_PARAM);
    await auth0Client.logout(params);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen: false,
        handleRedirectCallback,
        // loginWithPopup,
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        loginWithPopup: (...p) => auth0Client.loginWithPopup(...p),
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout
        // logout: (...p) => auth0Client.logout(...p)
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
